
























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import LocationTeaser from '../../../Component/Branch/BranchTeaser/BranchTeaser.vue';
import GreenContainer from '../../../Component/Layout/GreenContainer/GreenContainer.vue';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';

export default {
    name: 'BranchTeaser',
    components: {GreenContainer, Btn, LocationTeaser},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        branch()
        {
            if (this.context.initialState.has('id')) {
                return this.context.initialState;
            }
            return BranchAndGeoLocationService.getCurrentBranch();
        }
    }
};
