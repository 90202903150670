var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relevantTopicsModule" }, [
    _c(
      "div",
      { staticClass: "relevantTopicsModule__container" },
      [
        _c("h5", { staticClass: "relevantTopicsModule__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c("link-cloud", { attrs: { links: _vm.links } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }