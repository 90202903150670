



























import LinkCloud from '../../../../Misc/Link/LinkCloud/LinkCloud.vue';

export default {
    name: 'RelevantTopicsModule',
    components: {LinkCloud},
    props: {
        title: String,
        links: Array
    }
};
