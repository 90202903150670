var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.branch
    ? _c(
        "GreenContainer",
        { staticClass: "branchTeaserElement" },
        [
          _c("LocationTeaser", {
            attrs: {
              context: _vm.context,
              "is-horizontal": "",
              branch: _vm.branch,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }